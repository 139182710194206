import { getCookie } from "cookies-next";
import API from "rivals/services";
import { CSURF_TOKEN_COOKIE, USER_TOKEN_COOKIE } from "rivals/shared/constants";

// TODO: https://griosf.atlassian.net/browse/RVLS-9684
export function configureClientComponentApi(): API {
  const token = getCookie(USER_TOKEN_COOKIE);
  const csurf = getCookie(CSURF_TOKEN_COOKIE);

  return new API(false, token, csurf);
}
